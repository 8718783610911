<template>
  <div class="withdraw">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header">
          <div class="card-title">Запросить вывод средств</div>
        </div>
        <div class="card-body">
          <p>
            Осуществить вывод денег возможно только на банковский <b>расчётный счёт</b>
            с которого вы делали перечисления на номинальный счет платформы.
          </p>
          <div v-if="lenderVerified === false" class="card border-info">
            <div class="card-body">
              Для вывода средств необходимо
              <router-link :to="{ name: 'authorization' }" class="font-weight-bold text-nowrap">пройти
                авторизацию
              </router-link>

            </div>
          </div>
          <form v-if="lenderVerified" @input="validateForm" @change="validateForm"
                @submit.stop.prevent="submitForm">
            <div class="form-group">
              <label class="font-weight-bold" for="amount">ID {{ paymentCode }}</label>
              <div class="input-group  input-withdraw">
                <input-money v-model="amount"
                             class="form-control"
                             id="amount"
                             placeholder="Введите сумму"
                             :allow-fractions="true"
                             @blur ="updatePaymentPurpose"
                />

              </div>
              <ul class="navbar-nav ml-auto mr-3">
                <li class="nav-item dropdown">
                  <a class="nav-link font-weight-bold text-dark"
                     href="#extra-options"
                     id="extra-options-toggle"
                     role="button"
                     data-toggle="collapse"
                     aria-expanded="false"
                  >
                    Дополнительные возможности <i class="bi-caret-down"></i>
                  </a>

                </li>
              </ul>
              <div class="collapse" id="extra-options">
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <div class="tooltip-text">
                        <label class="text-muted" for="purpose">Назначение платежа </label>
                        <c-tooltip :text=this.purposeTooltip />
                      </div>
                      <div class="input-group">
                        <textarea
                            v-model="this.paymentPurpose"
                            type="text"
                            class="form-control"
                            id="purpose"
                            placeholder="Назначение платежа"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="text-muted">Счет вывода</label>
                        <div class="input-group">
                          <select class="form-control"
                                  autocomplete="off"
                                  @change="handleAccountChange"
                          >
                            <option disabled selected>Разверните для выбора счета</option>
                            <option v-for="item in requisites"
                                    :value="item.uuid"
                            >
                              {{ item.account }} — {{ item.bankName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group input-group-append">
                <button type="submit" class="btn btn-success">
                  Вывести
                </button>
              </div>
              <input-errors :errors="errors.amount"/>
            </div>
            <div class="text-muted mb-3">
              Минимальная сумма:
              <money :money="minWithdraw"/>
            </div>
          </form>
          <p>
            Согласно правилам пользования платформой, оператор платформы обязан осуществить
            вывод средств с номинального счет в течение 2 дней с момента получения запроса.
          </p>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {InputErrors, InputMoney, Money, Processing, PublicLink} from "@/components/common";
import {appConfig} from "@/configs";
import {Money as ApiMoney} from "@/utils/api-client";
import {createWithdrawManager} from "@/utils/di";
import {DashboardEventType} from "../enums/DashboardEventType";
import InputMask from "primevue/inputmask";
import CTooltip from "@/components/common/components/CTooltip.vue";
import moment from "moment/moment";

export default {
  name: "Withdraw",
  components: {
    CTooltip,
    PublicLink,
    InputErrors,
    InputMoney,
    Money,
    Processing,
    InputMask
  },
  inject: ["profile"],
  data() {
    return {
      amount: null,
      errors: {},
      processing: false,
      allowFractions: false,
      requisites: [],
      paymentPurpose: null,
      selectedRequisitesUuid: null,
    }
  },
  async mounted() {
    const manager = await createWithdrawManager();
    const requisites = await manager.getList();
    this.requisites = requisites.items;
  },
  computed: {
    purposeTooltip() {
      if (this.profile.lenderVerified) {
        if (this.profile.isEntrepreneur || this.profile.isLegalEntity) {
          return "Рекомендуем для ИП/ООО выводить отдельно займ и отдельно %. Пример заполнения \"Возврат основного долга / % по проекту  ООО \"Ромашка\" от 20.07.2023. Без НДС.\"";
        } else {
          return " Не рекомендуем менять назначения платежа для инвесторов физических лиц.";
        }
      }
      return "";
    },
    lenderVerified() {
      return this.profile.lenderVerified;
    },
    paymentCode() {
      return this.profile.paymentCode;
    },
    identificationDate() {
      if (this.profile.identificationDate) {
        return moment(this.profile.identificationDate).format("DD.MM.YYYY");
      }
    },
    balance() {
      return this.profile.balance || {};
    },
    minWithdraw() {
      return new ApiMoney(appConfig.minWithdraw * 100, "RUB");
    },
    withdrawPurpose() {
      return `Вывод средств в размере ${this.amount ? this.amount : 0} рублей lendly.ru в соответствии с договором от ${this.identificationDate}, ID ${this.paymentCode} без НДС.`;
    }
  },
  created() {
    this.paymentPurpose = this.withdrawPurpose;
  },
  methods: {
    validateForm() {
      const errors = {};
      try {
        this.amount = parseFloat(this.amount).toString();
      } catch {
        errors.amount = ['Введено некорректное значение'];
      }
      if (this.amount < this.minWithdraw.amount / 100) {
        errors.amount = ['Указанная сумма меньше минимальной суммы вывода'];
      } else if (this.amount > this.balance.amount / 100) {
        errors.amount = ['Указанная сумма больше доступной суммы для вывода'];
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          const manager = await createWithdrawManager();
          const amount = Math.floor( this.amount * 100 );
          const money = new ApiMoney(amount, this.balance.currencyCode);
          await manager.create(
              money,
              this.selectedRequisitesUuid ?? undefined,
              this.paymentPurpose ?? undefined
          );
          this.$emit("dashboardEvent", DashboardEventType.WithdrawRequested);
          await this.$router.push({name: "transactions"});
        } finally {
          this.processing = false;
        }
      }
    },
    handleAccountChange(e) {
      this.selectedRequisitesUuid = e.target.value;
    },
    updatePaymentPurpose() {
      this.paymentPurpose = this.paymentPurpose === null ? this.paymentPurpose : this.withdrawPurpose;
    }
  }
};
</script>

<style lang="scss">
.withdraw {
  .card {
    .card-body {
      form {
        .input-withdraw {
          max-width: 25rem;
        }
      }
    }
  }
}

.tooltip-text {
  display: flex;
  gap: 0.25rem;
}
</style>
